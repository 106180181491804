<template>
  <div class="row">
    <div class="col-12">
      <div class="row form">
        <div class="col-6">
          <C-Input
            id="firstName"
            v-model="getUserPref.firstName"
            type="text"
            input-control="md"
            placeholder=""
            :dirty="null"
            :disabled="true"
            :valid="false"
            label="First Name"
            help-text=""
            help-link=""
            help-link-url=""
            for-name="test"
            :errors="[]"
          />
        </div>
        <div class="col-6">
          <C-Input
            id="lastName"
            v-model="getUserPref.lastName"
            type="text"
            input-control="md"
            placeholder=""
            :dirty="null"
            :disabled="true"
            :valid="false"
            label="Last Name"
            help-text=""
            help-link=""
            help-link-url=""
            for-name="test"
            :errors="[]"
          />
        </div>
        <div class="col-6">
          <C-Input
            id="email"
            v-model="getUserPref.email"
            type="text"
            input-control="md"
            placeholder=""
            :dirty="null"
            :disabled="true"
            :valid="false"
            label="Email"
            help-text=""
            help-link=""
            help-link-url=""
            for-name="test"
            :errors="[]"
          />
        </div>
        <div class="col-6">
          <C-Input
            id="jobTitle"
            v-model="getUserPref.jobTitle"
            type="text"
            input-control="md"
            placeholder=""
            :dirty="null"
            :disabled="false"
            :valid="false"
            label="Job Title"
            for-name="jobTitle"
            :errors="[]"
          />
        </div>
        <div class="col-6">
          <Button
            size=""
            color="success"
            :outline="false"
            :block="false"
            :disabled="false"
            :href="null"
            :to="null"
            @clicked="updateUser"
          >
            {{save}}
          </Button>
        </div>
      </div>
      <c-toast
        ref="myToast"
        :toast-timer="toast.timer"
        :toast-class="toast.class"
        :toast-title="toast.title"
        :toast-content="toast.content"
      ></c-toast>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Button, CInput, CToast } from '@cendyn/cendyn-frontend-kit';

export default {
  name: 'UserGeneral',
  components: {
    Button, CInput, CToast
  },
  data() {
    return {
      picture: {
        title: 'Profile Picture',
        text: 'PNG or JPG no larger than 500px by 500px.',
        upload: 'Upload'
      },
      alertSuccess: false,
      alert: {
        color: 'success',
        icon: 'check-circle',
        message: 'Your account has been updated.',
        dismiss: false
      },
      save: 'Save Changes',
      toast: {
        timer: '3000',
        class: 'danger',
        title: '500 Error',
        content: 'There was a problem with your request. Try again or contact Cendyn if the problem continues.'
      }
    };
  },
  computed: {
    ...mapGetters('currentUser', [
      'getUserPref'
    ])
  },
  methods: {
    ...mapActions('currentUser', [
      'postCurrentUserAsync'
    ]),
    ...mapActions('alerts', [
      'alertAction'
    ]),
    updateUser() {
      this.postCurrentUserAsync( this.getUserPref )
        .then(() => {
          this.alertAction({
            alert: { icon: this.alert.icon, color: this.alert.color, dismissible: this.alert.dismiss, message: this.alert.message}
          }),
          setTimeout(() => { this.$store.commit('alerts/SHOW_ALERT', false); }, 3000);
        }).catch(err => {
          console.log('The Error ', err);
          this.$refs.myToast.$refs.toastRef.show();
        });
    }
  }
};
</script>

<style lang="scss">
  .profile_picture {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 36px;
    > div:not(:first-child) {
      padding-left: 24px;
    }
  }
  .profile_picture--text {
    flex: 1;
    span {
      display: block;
      &:not(:first-child) {
        color: #6E84A3
      }
    }
  }
</style>